import { render, staticRenderFns } from "./IndiceCadastro.vue?vue&type=template&id=1d52ae9d&scoped=true"
import script from "./IndiceCadastro.vue?vue&type=script&lang=js"
export * from "./IndiceCadastro.vue?vue&type=script&lang=js"
import style0 from "./IndiceCadastro.vue?vue&type=style&index=0&id=1d52ae9d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d52ae9d",
  null
  
)

export default component.exports