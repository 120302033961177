<template>
<div id="CadastroIndice">
      <v-card class="container">
        <v-card-title>
          <span class="text-h5">Cadastro de índices com valores</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12" class="px-0 mb-n5"
              >
                <v-select
                  label="Nome do índice"
                  filled
                  dense
                  background-color="map-deep-merge white"
                  v-model="indices"
                  :items="
                  [ 'IGPM',
                   'INCC-M',
                   'INDICE TJ (Acordo Jurídico)'
                  ]
                  "
                >
                
                <template v-slot:append-outer>
                    <v-icon  
                    @click="dialogNovoIndice = true"
                    color="primary"
                    >
                    mdi-plus-circle
                    </v-icon>
               </template>
               
               </v-select>
              </v-col>

       <!--------- NOVO ÍNDICE ------------------------------->
               <v-dialog
                  v-model="dialogNovoIndice"
                  persistent
                  max-width="440px"
                >
                  <v-card class="container">
                    <v-card-title>
                      <span class="text-h5">Novo Índice</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container class="px-0">
                        <v-row class="px-0">
                          <v-col
                            class="px-0 mb-n5"
                            cols="12"
                          >
                            <v-text-field
                              filled
                              dense
                              background-color="map-deep-merge white"
                              label="Novo índice"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                  <v-divider class="mx-4 mb-3"></v-divider>

                  <v-card-actions class="justify-center pb-2">
                    <v-btn
                      @click="dialogNovoIndice = false"
                      class="btn"
                      color="blue darken-1"
                      text
                    >
                      CANCELAR
                    </v-btn>
                    <v-btn
                      class="btn white--text"
                        color="primary accent-4"
                    >
                      SALVAR
                    </v-btn>
                  </v-card-actions>
                  </v-card>
                </v-dialog>
        <!--------- FIM NOVO ÍNDICE ------------------------------->
      
        <!-- TABELA INDICES -------------------------------------------------------->
                <div
                v-if="indices == 'IGPM'"
                  style="width: 100%"
                  class="
                    container-table
                    d-flex
                    flex-column
                    justify-space-between
                    mb-4"
                >  
                  <v-data-table
                  class="rounded"
                  :items="indices_selecionados"
                  :headers="headers_indice"
                  :loading="loading"
                  fixed-header
                  :items-per-page="10"
                  hide-default-footer
                  loading-text="Carregando...  aguarde..."
                  no-data-text="Nenhum Registro Encontrado"
                  no-results-text="Nenhum Registro Encontrado"
                  dense
                  >
                <template #item="{ item }">
                    <tr>
                      <td style="width: 50%">
                        {{ item.data_indice }}
                      </td>

                      <td style="width: 50%">
                        {{ item.valor_indice }}
                      </td>

                      <td class="td-edit">
                          <v-btn icon dark class="btn-icon mr-5" color="grey">
                            <v-icon class="icon-action"> mdi-pencil </v-icon>
                          </v-btn>
                      </td>

                      <td class="td-edit">
                          <v-btn icon dark class="btn-icon mr-5" color="grey">
                            <v-icon class="icon-action"> mdi-delete </v-icon>
                          </v-btn>
                      </td>
                    </tr> 

                </template>
              </v-data-table>
           </div>
            <!-- FIM TABELA INDICES -------------------------------------------------------->

            </v-row>
          </v-container>
          </v-card-text>
          <v-divider class="mx-4 mb-3"></v-divider>
          <v-card-actions class="justify-center">
            <v-btn
              class="btn"
              color="blue darken-1"
              text
            >
              CANCELAR
            </v-btn>
            <v-btn
              class="btn white--text"
                color="primary accent-4"
            >
              SALVAR
            </v-btn>
          </v-card-actions>
      </v-card>
  </div>
</template>

<script>
export default {
    
    name: "CadastroIndice",

      data() {
    return {

       indices: null,
      
       loading: true,

       dialogNovoIndice: false,

       headers_indice: [
        { text: "Mês/Ano", value: "Mês/Ano" },
        {text: "Valor do Índice", value: "Valor do Índice"},
        {text: ""},
        {text: ""},
      ],

        /* Dados Índice */
        indices_selecionados: [
        {data_indice: "11/2021", valor_indice: "0,000000"},
     ],
     
     dialogSociosCadastro: false,
      
    }
  }
}
</script>

<style scoped>
.container {
  background: #F2F6F7 !important;
}

.container-table {
  margin-bottom: 24px;
  border-radius: 8px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
  
.btn {
  width: 120px!important;
}
</style>



      